<template>
  <v-dialog
    v-model="internalShowing"
    max-width="400px"
  >
    <v-card>
      <v-card-text
        class="pt-4"
      >
        <v-form
          v-model="formValid"
        >
          <b10-dynamic-form
            v-model="formModel"
            :schema="formSchema"
            :options="formOptions"
          />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <template
          v-if="readonly"
        >
          <v-btn
            color="primary"
            @click.stop="clickCancelar"
          >
            Cerrar
          </v-btn>
        </template>
        <template
          v-else
        >
          <v-btn
            text
            @click.stop="clickCancelar"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="primary"
            @click.stop="clickAceptar"
          >
            Aceptar
          </v-btn>
        </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import internalShowingMixin from '@/mixins/internalShowingMixin'

export default {
  mixins: [internalShowingMixin],
  props: {
    item: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      default: 0,
    },
    readonly: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    return {
      formValid: false,
      formModel: {
        notas_internas: '',
        observaciones: '',
      },
      formSchema: {
        type: 'object',
        properties: {
          notas_internas: {
            type: 'string',
            title: 'Notas internas',
            readOnly: false,
            'x-display': 'textarea',
            'x-props': {
              clearable: true,
              'background-color': 'white',
            },
          },
          observaciones: {
            type: 'string',
            title: 'Observaciones (visibles para el cliente)',
            readOnly: false,
            'x-display': 'textarea',
            'x-props': {
              clearable: true,
              'background-color': 'white',
            },
          },
        },
      },
      formOptions: {
        locale: 'es',
      },
    }
  },
  watch: {
    item: {
      handler: async function (newValue) {
        this.$set(this.formModel, 'notas_internas', newValue.pregunta_checklist_ot.notas_internas)
        this.$set(this.formModel, 'observaciones', newValue.pregunta_checklist_ot.observaciones)
      },
      deep: true
    }
  },
  methods: {
    clickAceptar () {
      this.$emit('set-notas-internas-observaciones', { item: this.item, index: this.index, formData: this.formModel })
      this.internalShowing = false
    },
    clickCancelar () {
      this.internalShowing = false
    },
  }
}
</script>
